/* MEDIA RESOLUTION - 767px */

@media screen and (max-width:767px) {
    .sliderimg {
        display: none ! important;
    }

    .header-col-left,
    .header-col-right {
        width: 100% ! important;
    }

    .header-col-left img {
        width       : 30% ! important;
        margin-top  : 45px ! important;
        margin-left : auto ! important;
        margin-right: auto ! important;
    }

    .header-col-right h3 {
        font-size : 26px ! important;
        padding   : 0 80px !important;
        text-align: center !important;
        color     : #0e2a4b !important;
    }

    .header-container {
        background: #fff ! important;
    }

    img.at_image {

        float: initial ! important;
    }

    .award-col-left,
    .award-col-right {
        width: 100% ! important;
    }

    .award-col-left h2 {
        text-align: inherit ! important;
    }

    .awardPanel {
        height: 220px ! important;
    }

    .award-col-right {
        text-align: center ! important;
        padding   : 0 ! important;
    }

    .mainBanner {
        border: 0 ! important;
        height: 520px ! important;
    }

    .mainBanner h4 {

        font-size  : 30px !important;
        margin-left: 0 ! important;
        text-align : center ! important;
        font-weight: 400 ! important;
        position   : initial ! important;
    }

    .ausNetwork {
        display: inline-block ! important;
    }

    .ausNetwork_left {
        width: auto ! important;


    }


    .left_align h1 {
        font-size: 24px ! important;
        padding  : 10px 30px ! important;
    }

    .ausNetwork {
        width: auto ! important;
    }

    .homePanel1 {
        display: block ! important;
        width  : auto ! important;
    }


    .homePanel1,
    .ausNetwork {
        /*width    : 100% ! important;
        max-width: 100% ! important;*/

        height: auto ! important;

    }

    .col1_left_img img {
        margin-top: 10px;
    }

    .ausNetwork_right,
    .homePanel_right {
        height: auto ! important;

    }

    .awardPanel,
    .left_align h1 {
        padding: 0 ! important;
    }

    .homePanel_right {
        width: auto ! important;
    }

    .globalNetwork {
        width : 90% ! important;
        margin: 15px auto 30px auto;

    }

    slide-steps h5,
    .slide-steps p {
        text-align: left;
        width     : 100% ! important;
    }

    .slide-step_r2 {

        width: 100% ! important;

    }



    .slide-step_r2 p {
        display: none ! important;
    }

    .slide-step_r1 {
        width: 35% ! important;
    }

    .slide-steps img {
        float       : right ! important;
        width       : 60% ! important;
        margin-right: 0 ! important;
    }

    .footer_col1 {
        width     : auto ! important;
        display   : block ! important;
        margin    : auto ! important;
        text-align: center ! important;
    }

    .footer_col1 img {
        width  : 85% ! important;
        padding: 20px;

    }

    .footer-container {
        height: 115px ! important;
    }

    .footer_col1,
    .footer_col2,
    .footer_col3 {
        width: 30% !important;
    }

    .footer_body {

        width: 100% ! important;
        ;

    }

    .social-container {
        display: block;

        width: 144px ! important;

        margin: 12px auto;

    }

    .social_col1,
    .social_col2,
    .social_col3,
    .social_col4 {
        display     : block;
        float       : left;
        width       : 42% ! important;
        margin-top  : 6px;
        margin-right: -10px;
    }

    .social_col1 img,
    .social_col2 img,
    .social_col3 img,
    .social_col4 img {
        width      : 40px ! important;
        margin-left: 75px ! important;
    }

    .footer_col3 img {
        width : 54% ! important;
        float : initial ! important;
        margin: 18px 0 18px 70px ! important;
    }

    .award-col-left h2 {
        color: #fff;

        padding    : 15px 15px 5px 15px;
        font-size  : 24px;
        font-weight: 500 !important;
    }

    .ausNetwork_left {
        padding: 0 20px ! important;
    }

    .mob {
        display: block ! important;
    }

    .desk {
        display: none ! important;
    }

    .award-col-left h2 {
        color      : #fff;
        text-align : right;
        padding    : 8px 30px;
        font-size  : 22px;
        font-weight: 800 !important;

    }

    .mainBanner h2 {

        margin-top: 15%;


        font-size   : 20px;
        font-weight : 100;
        line-height : 1.2;
        text-align  : center;
        position    : relative;
        margin-left : auto;
        margin-right: auto;
        background  : #00000085;
        width       : 80%;
        padding     : 20px;

    }

    .mainBanner {
        background-position: top;

    }



    .slide-steps h5 {
        margin: 10px 0 10px 10px;
        width : 85%;
    }

    .slide-steps {
        padding: 0 ! important;
    }

    .mob-menu-container {
        display   : inline-block;
        margin    : auto;
        width     : 100%;
        padding   : 0;
        background: transparent;
        margin-top: 50px;
    }



    .mmmenu-col1 a,
    .mmmenu-col2 a,
    .mmmenu-col3 a,
    .mmmenu-col4 a,
    .mmmenu-col5 a,
    .mmmenu-col6 a {
        display: block;
        width  : 40%;

        padding        : 10px;
        color          : #fff !important;
        font-size      : 15px;
        text-align     : center;
        border         : 2px solid #fff !important;
        float          : left;
        background     : #00000073 !important;
        margin         : 5px 5px 5px 7px;
        text-decoration: none;
    }

    img.at_image {
        padding: 0 ! important;
        width  : 100% ! important;
        float  : initial;

    }

    .left_align {
        text-align: center ! important;
    }

    .slide-steps h5 {

        width      : 100% ! important;
        padding-top: 10px;

    }


}


@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .header-col-right h3 {
        font-size: 24px;
    }

    .header-col-left img {
        width: 25%;
    }

    .mainBanner {
        height             : 500px;
        background-position: center;
    }

    .mainBanner h2,
    .mainBanner h4 {
        margin-left: 15%;
    }

    .mainBanner h2 {
        margin-top: 15%;
        font-size : 35px ! important;

    }

    .mainBanner h4 {
        margin-top: 24%;
        font-size : 25px;
    }

    .sliderimg {
        /*width     : 55%;*/
        width     : auto;
        margin-top: 30px;
    }

    .header-col-left img {
        margin-left: 30%;
    }

    .homePanel_right {
        width       : 45%;
        padding-left: 40px;
    }

    img.at_image {
        margin-top: 30px;
    }

    .homePanel1 {
        display: block;
        width  : 100% ! important;
    }

    .award-col-right {
        margin-left: 35px;
        padding    : 15px 0 15px 0px;
    }

    img.award {
        width: 30%;
    }

    .award-col-right {
        width: 33%;
    }

    .award-col-left {
        width: 60%;
    }

    .award-col-left h2 {
        font-size: 25px;
    }

    .network img {
        width: 75% ! important;
    }

    .social-container {
        margin-top: 5px;
        width     : 120px;
        float     : left ! important;
    }


    .social_col1 img,
    .social_col2 img,
    .social_col3 img,
    .social_col4 img {
        width: 55px;
    }

    .footer_col1 img {
        width  : 55%;
        padding: 10px;
    }

    .footer_col3 img {
        float: left ! important;
    }
}


@media screen and (min-device-width: 820px) and (max-device-width: 1180px) {
    .header-col-right h3 {
        font-size: 20px ! important;
    }

    .mainBanner h2 {
        font-size: 25px;
    }

    .mainBanner h4 {
        font-size : 20px;
        margin-top: 27%;
    }
}

/* For Tablet View */
@media screen and (min-device-width: 1025px) and (max-device-width: 1440px) {
    .mainBanner h2 {
        font-size: 45px;
    }

    .mainBanner h2,
    .mainBanner h4 {
        margin-left: 20%;
    }

    .mainBanner h4 {
        margin-top: 19%;
        font-size : 25px;
    }

    .sliderimg {
        /*width     : 42%;*/
        width     : auto;
        margin-top: 23px;
    }

    .mainBanner {
        height: 500px;
    }

    .header-col-left img {
        width         : 25%;
        /* margin-left:16px;*/
    }

    .header-col-left,
    .header-col-right {
        width: 45%;
    }

}