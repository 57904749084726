 .App {
   text-align: center;
   margin-top: auto;
 }

 .App-logo {
   height        : 40vmin;
   pointer-events: none;
 }

 @media (prefers-reduced-motion: no-preference) {
   .App-logo {
     animation: App-logo-spin infinite 20s linear;
   }
 }

 .App-header {
   background-color: #282c34;
   min-height      : 100vh;
   display         : flex;
   flex-direction  : column;
   align-items     : center;
   justify-content : center;
   font-size       : calc(10px + 2vmin);
   color           : white;
 }

 .App-link {
   color: #61dafb;
 }

 .left_align{
 text-align:left;
 }



 @keyframes App-logo-spin {
   from {
     transform: rotate(0deg);
   }

   to {
     transform: rotate(360deg);
   }
 }


 