.ausNetwork {
    display  : flex;
    width    : 1147px;
    margin   : auto;
    padding  : 15px;
    /*border : 1px solid #ccc;*/
}

.ausNetwork_left,
.ausNetwork_right {
    float  : left;
    display: block;

    height: auto;
}

.ausNetwork_left {
    width: 56%;

    padding: 0 22px 0 22px;


}

.network_col2 h1 {
    padding-top: 30px;
}

img.at_image {
   
    width  : 100%;
    

}