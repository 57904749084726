.header-container {
    display   : block;
    width     : 100%;
    background: #153053 ! important;

    float: left;
}

.header-col-right h3 {
    font-size  : 30px;
    color      : #fff;
    font-weight: 200 ! important;
    text-align : left;
    padding    : 0;
}

h3 {
    padding: 0 !important;
}


.header-col-right img{
width:40%;;
    margin-top:35px;
    }


em.emph {
    font-style : normal;
    font-weight: 600;

}


.header-col-left,
.header-col-right {
    display   : flex;
    float     : inherit;
    width     : 50%;
    background: transparent;
}



.header-col-left img {
    width      : 18%;
    margin-left: 44%;

}

.sliderimg {
    /*width : 30%;*/
    width : auto;
    height: auto;

    margin-top : 60px;
    /*margin-left: 30%*/
    margin-left: 0;
}

.mob {
    display: none !important;
}

@media screen and (max-width:767px){
    
.header-col-right img{

    margin-bottom:30px;
    width:auto !important;
    }
}