body {
  margin                 : 0;
  padding                : 0;
  font-family            : 'Montserrat', sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;

}

p {
  color      : #6a6a6a;
  line-height: 1.5;
}