.homePanel1 {
    display: flex;
    width  : 1147px;
    margin : auto;
    padding: 15px;
}

.homePanel_left,
.homePanel_right {
    float  : left;
    display: block;

    /*height: 300px;*/
}



img.at_image {
    padding: 0;
    width  : 100%;

}

.homePanel_right {
    width  : 58%;
    padding: 0 25px;

}

.col1_left_img img {
    float     : left ! important;
    text-align: left;
}